$(document).ready(function () {

    //our own offcanvas panel
    $("#show").click(function () {
        showOffCanvas();
    });
    $("[data-action='showOffCanvas']").click(function () {
        showOffCanvas();
    });

    $("[data-action='hideOffCanvas']").click(function () {
        hideOffCanvas();
    });

    function showOffCanvas() {
        $("#offcanvasRight").addClass("show");
        $("#offcanvas-backdrop").addClass("show");
    }
    function hideOffCanvas() {
        $("#offcanvasRight").removeClass("show");
        $("#offcanvas-backdrop").removeClass("show");
    }
    //end - our own offcanvas panel


    //make all date inputs datapickers
    // $('.datepicker').datepicker();
    //$('.datepicker').datepicker({ format: 'yyyy-mm-dd' });
    $('.datepicker').datepicker({ 
        format: 'dd-mm-yyyy',
        todayHighlight:true
    });


    //$('.datepicker').datepicker({
    //    dateFormat: 'dd M yy',
    //    onSelect: function(dateText, inst) {
    //        var dateAsString = dateText; //the first parameter of this function
    //        var newDateFormat = $.datepicker.parseDate('dd-mm-yyyy', dateAsString);
    //    }
    //});

    $(function () {
        "use strict";
        $(function () {
            $(".preloader").fadeOut();
        });
        jQuery(document).on('click', '.mega-dropdown', function (e) {
            e.stopPropagation()
        });
        // ==============================================================
        // This is for the top header part and sidebar part
        // ==============================================================
        var set = function () {
            var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
            var topOffset = 55;
            if (width < 1170) {
                $("body").addClass("mini-sidebar");
                $('.navbar-brand span').hide();
                $(".sidebartoggler i").addClass("ti-menu");
            } else {
                $("body").removeClass("mini-sidebar");
                $('.navbar-brand span').show();
            }
            var height = ((window.innerHeight > 0) ? window.innerHeight : this.screen.height) - 1;
            height = height - topOffset;
            if (height < 1) height = 1;
            if (height > topOffset) {
                $(".page-wrapper").css("min-height", (height) + "px");
            }
        };
        $(window).ready(set);
        $(window).on("resize", set);
        // ==============================================================
        // Theme options
        // ==============================================================
        $(".sidebartoggler").on('click', function () {

            if ($("body").hasClass("mini-sidebar")) {
                $("body").trigger("resize");
                $("body").removeClass("mini-sidebar");
                $('.navbar-brand span').show();
            } else {
                $("body").trigger("resize");
                $("body").addClass("mini-sidebar");
                $('.navbar-brand span').hide();
            }
        });
        // this is for close icon when navigation open in mobile view
        $(".nav-toggler").click(function () {
            $("body").toggleClass("show-sidebar");
            $(".nav-toggler i").toggleClass("ti-menu");
            $(".nav-toggler i").addClass("ti-close");
        });
        $(".search-box a, .search-box .app-search .srh-btn").on('click', function () {
            $(".app-search").toggle(200);
        });
        // ==============================================================
        // Right sidebar options
        // ==============================================================
        $(".right-side-toggle").click(function () {
            $(".right-sidebar").slideDown(50);
            $(".right-sidebar").toggleClass("shw-rside");
        });
        // ==============================================================
        // This is for the floating labels
        // ==============================================================
        $('.floating-labels .form-control').on('focus blur', function (e) {
            $(this).parents('.form-group').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
        }).trigger('blur');

        // ==============================================================
        //tooltip
        // ==============================================================
        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        })
        // ==============================================================
        //Popover
        // ==============================================================
        $(function () {
            $('[data-toggle="popover"]').popover()
        })

        // ==============================================================
        // Perfect scrollbar
        // ==============================================================
        $('.scroll-sidebar, .right-side-panel, .message-center, .right-sidebar').perfectScrollbar();
        $('#chat, #msg, #comment, #todo').perfectScrollbar();
        // ==============================================================
        // Resize all elements
        // ==============================================================
        $("body").trigger("resize");
        // ==============================================================
        // To do list
        // ==============================================================
        $(".list-task li label").click(function () {
            $(this).toggleClass("task-done");
        });
        // ==============================================================
        // Collapsable cards
        // ==============================================================
        $('a[data-action="collapse"]').on('click', function (e) {
            e.preventDefault();
            $(this).closest('.card').find('[data-action="collapse"] i').toggleClass('ti-minus ti-plus');
            $(this).closest('.card').children('.card-body').collapse('toggle');
        });
        // Toggle fullscreen
        $('a[data-action="expand"]').on('click', function (e) {
            e.preventDefault();
            $(this).closest('.card').find('[data-action="expand"] i').toggleClass('mdi-arrow-expand mdi-arrow-compress');
            $(this).closest('.card').toggleClass('card-fullscreen');
        });
        // Close Card
        $('a[data-action="close"]').on('click', function () {
            $(this).closest('.card').removeClass().slideUp('fast');
        });
        // ==============================================================
        // Color variation
        // ==============================================================

        var mySkins = [
            "skin-default",
            "skin-green",
            "skin-red",
            "skin-blue",
            "skin-purple",
            "skin-megna",
            "skin-default-dark",
            "skin-green-dark",
            "skin-red-dark",
            "skin-blue-dark",
            "skin-purple-dark",
            "skin-megna-dark"
        ]

        /**
         * Get a prestored setting
         *
         * @param String name Name of of the setting
         * @returns String The value of the setting | null
         */
        function get(name) {
            if (typeof (Storage) !== 'undefined') {
                return localStorage.getItem(name)
            } else {
                window.alert('Please use a modern browser to properly view this template!')
            }
        }

        /**
         * Store a new settings in the browser
         *
         * @param String name Name of the setting
         * @param String val Value of the setting
         * @returns void
         */
        function store(name, val) {
            if (typeof (Storage) !== 'undefined') {
                localStorage.setItem(name, val)
            } else {
                window.alert('Please use a modern browser to properly view this template!')
            }
        }

        /**
         * Replaces the old skin with the new skin
         * @param String cls the new skin class
         * @returns Boolean false to prevent link's default action
         */
        function changeSkin(cls) {
            $.each(mySkins, function (i) {
                $('body').removeClass(mySkins[i])
            })
            $('body').addClass(cls)
            store('skin', cls)
            return false
        }

        function setup() {
            var tmp = get('skin')
            if (tmp && $.inArray(tmp, mySkins)) changeSkin(tmp)
            // Add the change skin listener
            $('[data-skin]').on('click', function (e) {
                if ($(this).hasClass('knob')) return
                e.preventDefault()
                changeSkin($(this).data('skin'))
            })
        }

        setup()
        $("#themecolors").on("click", "a", function () {
            $("#themecolors li a").removeClass("working"),
                $(this).addClass("working")
        })

        // For Custom File Input
        $('.custom-file-input').on('change', function () {
            //get the file name
            var fileName = $(this).val();
            //replace the "Choose a file" label
            $(this).next('.custom-file-label').html(fileName);
        })
    });


    /**
     *
     **** DATATABLES START *****
     *
     *  */


    // var myTable = $('#tableSelector').DataTable({
    var myTable = $('.my_datatable').DataTable({
        // var myTable = $(thisTable).DataTable({
        lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, 'All']],
        responsive: true,
        stateSave: true,
        dom: 'Bfrtilp',
        buttons: [
            'copy', 'csv', 'excel', 'pdf', 'print'
        ],
        "order": [[0, "desc"]]
    });
    $('.buttons-copy, .buttons-csv, .buttons-print, .buttons-pdf, .buttons-excel').addClass('btn btn-primary mr-1');

    //this is to register events on newly visible rows/elements
    myTable.on('draw', function () {

        //console.log('Redraw occurred at: ' + new Date().getTime());
        //add forms to the links - see custom.js
        addConfirmForms();
        addDeleteForms();
    });


    $(document).on("DataTableRendered", function (event, data) {
        //console.log('DataTableRendered')
        addConfirmForms();
        addDeleteForms();
    });




    /* add filters */

    $(".modAcTitle").each(function (i) {
        var $content = $('.content').eq(i);
        $(this).clone().prependTo($content);
    });


    $('.my_datatable thead tr th').each(function (i) {

        var title = $(this).text();

        if ($(this).hasClass('filterable')) {

            //console.log('has filterable');
            //console.log(title + ' filterable');

            if ($(this).hasClass('dropdown')) {

                //dropdown select filter
                var selectHTML = '<br /><select name="" class="custom_filter">';
                var values = getFilterData(i);

                selectHTML += '<option value="">-- geen filter --</option>';
                values.forEach(function (item) {
                    selectHTML += '<option value="' + item + '" >' + item + '</option>';
                });
                selectHTML += '</select>';
                $(this).append(selectHTML);


            } else {

                //default: text input
                //  $(this).html('<input class="custom_filter" type="text" placeholder="Search ' + title + '" />');
                $(this).append('<br /><input class="custom_filter" type="text" placeholder="Search ' + title + '" />');
            }
        } else {
            //  $(this).html('');
        }

        $('.custom_filter', this).on('keyup change', function () {
            if (myTable.column(i).search() !== this.value) {
                myTable.column(i).search(this.value).draw();
            }
        });


    });

    function getFilterData(i) {

        var data = myTable.columns(i).data().unique().sort();

        var output = [];
        data[0].forEach(function (item) {
            output.push(stripHtml(item));
        });
        var uniqueOutput = Array.from(new Set(output))
        return uniqueOutput;
        //  return data[0];
    }

    function stripHtml(html) {
        // Create a new div element
        var temporalDivElement = document.createElement("div");
        // Set the HTML content with the providen
        temporalDivElement.innerHTML = html;
        // Retrieve the text property of the element (cross-browser support)
        return temporalDivElement.textContent || temporalDivElement.innerText || "";
    }

    /**
     *
     **** DATATABLES END *****
     *
     *  */

    //  });


    //if ($('#myTable2').length > 0) {
    //    var myTable2 =  $('#myTable2').DataTable({
    //        dom: 'Bfrtip',
    //        buttons: [
    //            'copy', 'csv', 'excel', 'pdf', 'print'
    //        ],
    //       // "order": [[ 0, "desc" ]],
    //        responsive: true
    //    });
    //    $('.buttons-copy, .buttons-csv, .buttons-print, .buttons-pdf, .buttons-excel').addClass('btn btn-primary mr-1');
    //
    //    var table = $('#example').DataTable();
    //
    //    //this is to register events on newly visible rows/elements
    //    myTable2.on( 'draw', function () {
    //        console.log( 'Redraw occurred at: '+new Date().getTime() );
    //        //add forms to the links - see custom.js
    //        addConfirmForms();
    //        addDeleteForms();
    //    } );
    //
    //};

    /*
     // recalc table when bootstrap tab is clicked
     $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
     var target = $(e.target).attr("href") // activated tab

     myTable.responsive.recalc();
     myTable2.responsive.recalc();
     });
     */

    //FANCYBOX for all links with class 'edit_fancybox'
    //mousedown comes before the click event that fancybox uses - this makes it works on dynamic elements
    // $("#myTable, #myTable2").on('mousedown', 'a.edit_fancybox', function(event){
    $(document).on('mousedown', 'a.edit_fancybox', function (event) {

        event.preventDefault();

        $("a.edit_fancybox").fancybox({
            'type': 'ajax',
            'transitionIn': 'elastic',
            'transitionOut': 'elastic',
            'speedIn': 600,
            'speedOut': 200,
            'overlayShow': false,
            afterShow: function (instance, slide) {

                // Tip: Each event passes useful information within the event object:

                // Object containing references to interface elements
                // (background, buttons, caption, etc)
                // console.info( instance.$refs );

                // Current slide options
                // console.info( slide.opts );

                // Clicked element
                // console.info( slide.opts.$orig );

                // Reference to DOM element of the slide
                // console.info( slide.$slide );

                addConfirmForms();
                addDeleteForms();
            }
        });

    });


    /* CHECKALL CHECKBOXES STUFF */


    $(".checkAll").change(function () {

        var currentTable = $(this).closest("table");

        if (this.checked) {
            currentTable.find(":checkbox.checkSingle").prop("checked", true);
        } else {
            currentTable.find(":checkbox.checkSingle").prop("checked", false);
        }

        updateMassactionVisibility(currentTable);
    });

    //extra 'checkall' button
    $(".checkAllButton").click(function (e) {
        e.preventDefault();

        console.log('click');
        var tableId = $(this).data('target-table-id');

        console.log(tableId);
        //var currentTable = $(this).closest("table");
        var currentTable = $('#' + tableId);

        console.log(currentTable);

        currentTable.find(":checkbox.checkSingle").prop("checked", true);
        currentTable.find(".checkAll").prop("checked", true);
    });

    $(".checkSingle").click(function () {

        var currentTable = $(this).closest("table");

        if ($(this).is(":checked")) {
            var isAllChecked = 0;

            currentTable.find(".checkSingle").each(function () {
                if (!this.checked)
                    isAllChecked = 1;
            });

            if (isAllChecked == 0) {
                currentTable.find(".checkAll").prop("checked", true);
            }
        } else {
            currentTable.find(".checkAll").prop("checked", false);
        }
        updateMassactionVisibility(currentTable);
    });

    function updateMassactionVisibility(currentTable) {

        //el is the clicked checkbox or the check-all checkbox

        // var currentTable = el.closest("table");
        //var currentMassactionsContainer = el.siblings(".mass-actions");
        var anyoneChecked = 0;

        currentTable.find(".checkSingle").each(function () {
            if (this.checked) {
                anyoneChecked = 1;
            }
        });

        if (anyoneChecked == 1) {
            showMassActions(currentTable);
        } else {
            hideMassActions(currentTable);
        }
    }

    //reset - disabled, none selected, muted
    function hideMassActions(currentTable) {
        var currentMassactionsContainer = currentTable.siblings(".mass-actions");

        currentMassactionsContainer.addClass('text-muted');

        currentMassactionsContainer.find('select[name="massaction"] option[value=""]').attr("selected", true);
        currentMassactionsContainer.find('select[name="massaction"]').val('').change();
        currentMassactionsContainer.find('select[name="massaction"]').prop("disabled", true);

        currentMassactionsContainer.find(".mass-actions input, .mass-actions select").each(function () {
            $(this).prop("disabled", true);
        });

    }

    function showMassActions(currentTable) {

        var currentMassactionsContainer = currentTable.siblings(".mass-actions");

        currentMassactionsContainer.removeClass('text-muted');

        currentMassactionsContainer.find(".mass-actions").removeClass('text-muted');
        currentMassactionsContainer.find('select[name="massaction"]').prop("disabled", false);


        currentMassactionsContainer.find(".mass-actions input, .mass-actions select").each(function () {
            $(this).prop("disabled", false);
        });
    }

    //visibility of sub-masactions
    $('[name=massaction]').change(function () {

        var currentMassactionsContainer = $(this).closest(".mass-actions");

        //var show = $("[name=massaction] option:selected").data('show');
        var show = currentMassactionsContainer.find("[name=massaction] option:selected").data('show');

        //hide all
        //$(".massaction-option").each(function () {
        currentMassactionsContainer.find(".massaction-option").each(function () {
            $(this).hide();
        });

        //show according to data-show
        if (show) {
            //$("." + show).each(function () {
            currentMassactionsContainer.find("." + show).each(function () {
                $(this).show();
            });
        }
    });


    //on page load - hide all mass actions for all tables on this page
    $(".mass-actions").each(function () {
        // hideMassActions expects the -table- element.
        hideMassActions($(this).siblings("table"));
    });

});


/**
 * Allows you to add data-method="METHOD to links to automatically inject a form
 * with the method on click
 *
 * Example: <a href="{{route('customers.destroy', $customer->id)}}"
 * data-method="delete" name="delete_item">Delete</a>
 *
 * Injects a form with that's fired on click of the link with a DELETE request.
 * Good because you don't have to dirty your HTML with delete forms everywhere.
 */
function addDeleteForms() {
    $('a[data-method="delete"]').append(function () {
        //  $('a[data-method="delete"]:not(:has(form))').append(function () {
        if (!$(this).find('form').length > 0) {
            return "\n<form action='" + $(this).attr('href') + "' method='POST' name='delete_item' style='display:none'>\n" +
                "<input type='hidden' name='_method' value='" + $(this).attr('data-method') + "'>\n" +
                "<input type='hidden' name='_token' value='" + $('meta[name="csrf-token"]').attr('content') + "'>\n" +
                '</form>\n';
        } else {
            return ''
        }
    })
        .attr('href', '#')
        .attr('style', 'cursor:pointer;')
        .attr('onclick', '$(this).find("form").submit();');
}

function addConfirmForms() {
    $('a[name="confirm_item_post"]').append(function () {
        if (!$(this).find('form').length > 0) {
            return "\n<form action='" + $(this).attr('href') + "' method='POST' name='confirm_item_post' style='display:none'>\n" +
                "<input type='hidden' name='_method' value='" + $(this).attr('data-method') + "'>\n" +
                "<input type='hidden' name='_token' value='" + $('meta[name="csrf-token"]').attr('content') + "'>\n" +
                '</form>\n';
        } else {
            return ''
        }
    })
        .attr('href', '#')
        .attr('style', 'cursor:pointer;')
        .attr('onclick', '$(this).find("form").submit();');
}


/**
 * Place any jQuery/helper plugins in here.
 */
$(document).ready(function () {
    /**
     * Add the data-method="delete" forms to all delete links
     */
    addDeleteForms();
    addConfirmForms();
    /**
     * Disable all submit buttons once clicked
     */
    $('form').submit(function () {
        $(this).find('input[type="submit"]').attr('disabled', true);
        $(this).find('button[type="submit"]').attr('disabled', true);
        return true;
    });

    /**
     * Generic confirm form delete using Sweet Alert
     */
    $('body').on('submit', 'form[name=delete_item]', function (e) {
        e.preventDefault();

        const form = this;
        const link = $(this).parents('a[data-method="delete"]');
        const cancel = (link.attr('data-trans-button-cancel')) ? link.attr('data-trans-button-cancel') : 'Cancel';
        const confirm = (link.attr('data-trans-button-confirm')) ? link.attr('data-trans-button-confirm') : 'Yes, delete';
        const title = (link.attr('data-trans-title')) ? link.attr('data-trans-title') : 'Are you sure you want to delete this item?';

        Swal.fire({
            title: title,
            showCancelButton: true,
            confirmButtonText: confirm,
            cancelButtonText: cancel,
            type: 'warning'
        }).then((result) => {
            result.value && form.submit();
        });
    }).on('submit', 'form[name=confirm_item_post]', function (e) {
        e.preventDefault();

        const form = this;
        const link = $(this).parents('a[name="confirm_item_post"]');
        const cancel = (link.attr('data-trans-button-cancel')) ? link.attr('data-trans-button-cancel') : 'Cancel';
        const confirm = (link.attr('data-trans-button-confirm')) ? link.attr('data-trans-button-confirm') : 'Yes';
        const title = (link.attr('data-trans-title')) ? link.attr('data-trans-title') : 'Are you sure?';

        Swal.fire({
            title: title,
            showCancelButton: true,
            confirmButtonText: confirm,
            cancelButtonText: cancel,
            type: 'warning'
        }).then((result) => {
            result.value && form.submit();
        });
    }).on('click', 'a[name=confirm_item]', function (e) {
        /**
         * Generic 'are you sure' confirm box
         */
        e.preventDefault();

        const link = $(this);
        const title = (link.attr('data-trans-title')) ? link.attr('data-trans-title') : 'Are you sure you want to do this?';
        const cancel = (link.attr('data-trans-button-cancel')) ? link.attr('data-trans-button-cancel') : 'Cancel';
        const confirm = (link.attr('data-trans-button-confirm')) ? link.attr('data-trans-button-confirm') : 'Continue';

        Swal.fire({
            title: title,
            showCancelButton: true,
            confirmButtonText: confirm,
            cancelButtonText: cancel,
            type: 'info'
        }).then((result) => {
            result.value && window.location.assign(link.attr('href'));
        });
    })
        ;


    $('[data-toggle="tooltip"]').tooltip();
})
    ;


/* link directly to bootstrap tab*/
$(document).ready(function () {

    // Javascript to enable link to tab
    var hash = document.location.hash;
    var prefix = "tab_";
    if (hash) {
        console.log('hash');
        $('.nav-tabs a[href="' + hash.replace(prefix, "") + '"]').tab('show');
    }

    // Change hash for page-reload
    $('.nav-tabs a').on('shown', function (e) {
        window.location.hash = e.target.hash.replace("#", "#" + prefix);
    });


});

$(document).ready(function () {

    //init select2 on load
    // $('.select2').select2();


    //function to call from Livewire to init Select2
    window.initSelect2 = (config = {}) => {
        $('.select2').select2(config);
    }
    initSelect2(); //init select2 on load
 

    ///init select2 by firing select2 event from Livewire on hydrate
    window.livewire.on('select2', ({config}) => {
        var configObj = JSON.parse(config);
        initSelect2(configObj);
    }); 



    //push changes to Livewire
    // TODO: make this work with input event and standard Livewire model binding?
    $('.select2').on('change', function (e) {
        // e.target.dispatchEvent(new Event('input'));
        //e.target.value doesn't pass ALL values if it's a select-multiple
        var values = $(e.target).val(); //get ALL selected values
        var name = $(e.target).attr("name");; //get ALL selected values

        // console.log('emit select2 change');
        Livewire.emit('select2_updated', name, values)
    });

});

$(document).on('mousedown', 'a.edit_lead_fancybox', function (event) {

    event.preventDefault();

    $("a.edit_lead_fancybox").fancybox({
        src: $(event.currentTarget).data('lead'),
        type: 'inline',
        transitionIn: 'elastic',
        transitionOut: 'elastic',
        speedIn: 600,
        speedOut: 200,
        overlayShow: false,
        afterShow: function (instance, slide) {

            // Tip: Each event passes useful information within the event object:

            // Object containing references to interface elements
            // (background, buttons, caption, etc)
            // console.info( instance.$refs );

            // Current slide options
            // console.info( slide.opts );

            // Clicked element
            // console.info( slide.opts.$orig );

            // Reference to DOM element of the slide
            // console.info( slide.$slide );

            addConfirmForms();
            addDeleteForms();
        }
    });

});