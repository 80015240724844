window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) { }


/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });


//jquiry-ui stuff todo: we must include every widget that we need separately!
var autocomplete = require("jquery-ui/ui/widgets/autocomplete");
var selectable = require("jquery-ui/ui/widgets/selectable");


import Swal from 'sweetalert2';
window.Swal = Swal;

var datepicker = require("bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js");
window.$.datepicker = datepicker;

var datetimepicker = require('../vendor/timepicker-addon/timepicker-addon.js');
var timepicker = require('../vendor/user-friendly-timepicker/js/timepicker');



var Raphael = require("raphael/raphael.js");
window.Raphael = Raphael;

// var morris = require( "morris.js/morris.js" );

var fancybox = require("@fancyapps/fancybox");

var sparkline = require('sparkline');
window.$.sparkline = sparkline;

var perfectScrollbar = require('../vendor/perfect-scrollbar/perfect-scrollbar.jquery.min.js');
var select2 = require('select2');

//optgroups plugin for select2: https://github.com/bnjmnhndrsn/select2-optgroup-select
var selec2optgroups = require("../vendor/select2-optgroups/select2.optgroupSelect.js");


var toastr = require('toastr');
var steps = require('jquery-steps/build/jquery.steps.js');
var validation = require('jquery-validation');

//todo: which one?
//var datatables = require('datatables');
var datatables = require('datatables.net-bs4');

var Choices = require('choices.js');
window.Choices = Choices;


import EditorJS from '@editorjs/editorjs';
window.EditorJS = EditorJS;

import List from '@editorjs/list';
window.List = List;

import Header from '@editorjs/header';
window.Header = Header;


var Diff = require('text-diff');
window.Diff = Diff;


import Quill from "quill";
window.Quill = Quill;



/* IMPORT ALPINEJS */
// import 'alpinejs'  // changed to CDN to avoid the problem "$wire is not defined' -- also, to 'Make sure Livewire's scripts are loaded before Alpine'


// import { Chart } from 'chart.js';
// window.Chart = Chart;

// import ApexCharts from 'apexcharts'
// window.ApexCharts = ApexCharts;